import { graphql } from "gatsby"
import { Article, Section } from "../components/containers"
import { AllTags } from "../components/blog"
import { ArticleTitle } from "../components/headings"
import Seo from "../components/Seo"

const AllTagsTemplate = ({
  data: {
    allBlogPost: { group }
  },
  location
}) => {
  const pageTitle = "All Tags"

  return (
    <>
      <Seo
        pageTitle={pageTitle}
        pageDescription="Author’s blog"
        pageUrl={location.pathname}
      />
      <Article>
        <ArticleTitle title={pageTitle} />
        <Section semantic={false}>
          <AllTags tags={group} />
        </Section>
      </Article>
    </>
  )
}

export default AllTagsTemplate

export const allTagsTemplateQuery = graphql`
  query AllTagsTemplateQuery {
    allBlogPost(filter: { published: { eq: true } }) {
      group(field: tags) {
        fieldValue
        totalCount
      }
    }
  }
`
